import React, { useContext } from "react"
import classNames from "classnames"
import { Link, useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

// Components
import {
  Row,
  Container
} from "../../../ui-kit"
// Icons
import FacebookIcon from "../../../icons/footer/facebook.svg"
import YoutubeIcon from "../../../icons/footer/youtube.svg"
import InstagramIcon from "../../../icons/footer/instagram.svg"
// Styles
import s from "./Footer.module.sass"
// context
import { LayoutContext } from "../../Layouts/layout"
// icons
import Location from "../../../icons/form-order/location.svg"
import Envelope from "../../../icons/form-order/envelope.svg"
import Rova from "../../../icons/footer/rova.svg"

const Footer = () => {
  const {local: {langBase, YAMLGlobal}} = useContext(LayoutContext);
  
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "logo-header.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      directusGenericInformation {
          privacy_policy {
              localFile {
                  publicURL
              }
          }
      }
      form: directusGenericInformation {
        form_address
        form_address_ua
        form_email
      }
    }
  `)

  const privacy = data.directusGenericInformation.privacy_policy.localFile?.publicURL

  return (
    <footer className={classNames(s.footer)}>
      <Container>
        <Row align="align-items-center" justify="justify-content-between">
          <div className={s.icons}>
            <a noreferrer="noreferrer" id='inst' href="https://www.facebook.com/Utemsolar/" target="_blank" className={s.icon}><FacebookIcon/></a>
            <a noreferrer="noreferrer" id='fb' href="https://www.youtube.com/c/UtemOrgUa1" target="_blank" className={s.icon}><YoutubeIcon/></a>
            <a noreferrer="noreferrer" id='youtube' href="https://www.instagram.com/utem_solar/" target="_blank" className={s.icon}><InstagramIcon/></a>
          </div>
          <div className={s.address}>
            <p className={s.location}><i className={s.addressIcon}><Location/></i>{langBase === "/ua" ? data.form.form_address_ua : data.form.form_address}</p>
            <a href={`mailto:${data.form.form_email}`} className={s.email}><i className={s.addressIcon}><Envelope/></i>{data.form.form_email}</a>
          </div>
          <div className={s.brand}>
            <Link to={`${langBase}/`} className={s.img}><Img fluid={data.placeholderImage.childImageSharp.fluid} /></Link>
          </div>
        </Row>
        <div className={s.bottom}>
          <Row align="align-items-center" justify="justify-content-between">
            <a href={privacy} download className={s.policy}>{YAMLGlobal.footer.policy}</a>
            <a href="https://rova.agency" target="_blank" noreferrer="noreferrer" className={s.link}>{YAMLGlobal.developedBy} <Rova/></a>
          </Row>
        </div>
      </Container>
      
    </footer>
  )
}

export default Footer
