import React, { useRef } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

// Components
import { 
  Header,
  HeaderMob,
  Footer
} from "../Global"

// Styles
import "./layout.css"
import "../../styles/styles.sass"

const LayoutContext = React.createContext();

const Layout = ({ children, local, location }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const formRef = useRef(null);
  const onScrollToForm = () => {
      const element = formRef.current

      doScrolling(getOffsetTop(element), 800)
  }
  const getOffsetTop = (element) => {
      let yPosition = 0;
  
      while(element) {
          yPosition += (element.offsetTop - element.scrollTop + element.clientTop);
          element = element.offsetParent;
      }
  
      return yPosition;
  }
  
  function doScrolling(elementY, duration) { 
    var startingY = window.pageYOffset;
    var diff = elementY - startingY;
    var start;
  
    // Bootstrap our animation - it will get called right before next frame shall be rendered.
    window.requestAnimationFrame(function step(timestamp) {
      if (!start) start = timestamp;
      // Elapsed milliseconds since start of scrolling.
      var time = timestamp - start;
      // Get percent of completion in range [0, 1].
      var percent = Math.min(time / duration, 1);
  
      window.scrollTo(0, startingY + diff * percent);
  
      // Proceed with animation as long as we wanted it to.
      if (time < duration) {
        window.requestAnimationFrame(step);
      }
    })
  }
  
  const layoutState = {
    formRef,
    onScrollToForm,
    local
  }

  return (
    <LayoutContext.Provider value={layoutState} className="layout">
      <Header siteTitle={data.site.siteMetadata.title} location={location} />
      <HeaderMob siteTitle={data.site.siteMetadata.title} location={location} />
      <main>{children}</main>
      <Footer />
    </LayoutContext.Provider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export {LayoutContext}
export default Layout;