import React, { useContext } from 'react';
import { Link } from 'gatsby';
// styles
import s from "./Breadcrumbs.module.sass"
// ui-kit
import { Container } from "../../../ui-kit"
// svg
import Arrow from "../../../icons/breadcrumbs/arrow.svg"
// context
import { LayoutContext } from "../../Layouts/layout"

const Breadcrumbs = ({list = []}) => {
    const {local: {langBase}} = useContext(LayoutContext);
    
    if (list.length === 0) {
        return null;
    }
    
    return (
        <div className={s.breadcrumbs}>
            <Container>
                <div className={s.breadcrumbsInner}>
                    {
                        list.map(({link, title}, index) => {
                            return (
                                index + 1 === list.length ? 
                                    <span className={s.breadcrumbsLink} key={index}>{title}</span> : 
                                    <Link className={s.breadcrumbsLink} to={`${langBase}${link}`} key={index}>{title}<Arrow className={s.icon}/></Link>
                                        
                            )
                        }) 
                    }
                </div>
            </Container>
        </div>
    )
}

export default Breadcrumbs;