/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * 12345
 * 
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import defaultOpenGraphImage from "../images/og_image.jpg"
import { LayoutContext } from "./Layouts/layout"

function SEO({ description, lang, meta, title, image }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            descriptionUA
            author
            siteUrl
          }
        }
      }
    `
  )

  const metaDescription = description || ( lang === 'ua' ? site.siteMetadata.description : site.siteMetadata.descriptionUA)

  const ogImageUrl =
    site.siteMetadata.siteUrl + (image || defaultOpenGraphImage)

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          property: `og:image`,
          content: ogImageUrl,
        },
        {
          property: `twitter:image`,
          content: ogImageUrl,
        },
        {
          property: `image`,
          content: ogImageUrl,
        },
      ].concat(meta)}
    >
      <script async src="https://www.googletagmanager.com/gtag/js?id=G-W5EPGLFV1Z" />
      <script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag("js", new Date());

          gtag("config", "G-W5EPGLFV1Z");
        `}
      </script>
      <script>
        {`(function(w, d, s, l, i) {
          w[l] = w[l] || []
          w[l].push({
            "gtm.start":
              new Date().getTime(), event: "gtm.js"
          })
          var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s), dl = l != "dataLayer" ? "&l=" + l : ""
          j.async = true
          j.src =
            "https://www.googletagmanager.com/gtm.js?id=" + i + dl
          f.parentNode.insertBefore(j, f)
        })(window, document, "script", "dataLayer", "GTM-NS98QQ5");`}
      </script>
      <script>
        {`!function(f, b, e, v, n, t, s) {
          if (f.fbq) return
          n = f.fbq = function() {
            n.callMethod ?
              n.callMethod.apply(n, arguments) : n.queue.push(arguments)
          }
          if (!f._fbq) f._fbq = n
          n.push = n
          n.loaded = !0
          n.version = "2.0"
          n.queue = []
          t = b.createElement(e)
          t.async = !0
          t.src = v
          s = b.getElementsByTagName(e)[0]
          s.parentNode.insertBefore(t, s)
        }(window, document, "script",
          "https://connect.facebook.net/en_US/fbevents.js");
          fbq("init", "157103016048205");
          fbq("track", "PageView");`}
      </script>

      <noscript>
        {`<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-NS98QQ5" height="0" width="0"
                 style="display:none;visibility:hidden"/>`}
      </noscript>
      <noscript>
        {`<img height="1" width="1" style="display:none"
              src="https://www.facebook.com/tr?id=157103016048205&ev=PageView&noscript=1"/>`}
      </noscript>

    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
