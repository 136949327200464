import React, {useState, useContext, useEffect} from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Link } from "gatsby"
import Img from "gatsby-image"
import { motion } from "framer-motion"
import classNames from "classnames"
// ui-kit
import { Row, Container } from "../../../ui-kit"
// styles
import s from './Header.module.sass'
// svg
import Facebook from "../../../icons/header/facebook.svg"
import Instagram from "../../../icons/header/instagram.svg"
import Youtube from "../../../icons/header/youtube.svg"
import Burger from "../../../icons/header/burger.svg"
import Phone from "../../../icons/header/phone.svg"
import Close from "../../../icons/header/close.svg"
// ui-kit
import {
    Accordion
} from '../../../ui-kit'
// context
import {LayoutContext} from '../../Layouts/layout'

const HeaderMob = ({location}) => {
    const {local: { langBase }} = useContext(LayoutContext);
    
    const data = useStaticQuery(graphql`
        query {
            placeholderImage: file(relativePath: { eq: "logo-header.png" }) {
                childImageSharp {
                    fluid(maxWidth: 400) {
                        ...GatsbyImageSharpFluid
                    }
                }
            },
            projects: allDirectusProjectsCategory(limit: 1) {
                edges {
                    node {
                        slug
                    }
                }
            },
            video: allDirectusVideosCategory(limit: 1) {
                edges {
                    node {
                        slug
                    }
                }
            },
            info: allDirectusGenericInformation {
                edges {
                    node {
                        instagram
                        facebook
                        youtube
                        phone_number_1
                        phone_number_2
                        phone_number_3
                        form_address
                        form_address_ua
                        form_email
                    }
                }
            }
        }
    `)

    const [isOpen, setOpen] = useState(false);
    const [isVisible, setVisible] = useState(false);
    
    const [isOpenC, setOpenC] = useState(false);
    const [isVisibleC, setVisibleC] = useState(false);
    
    const menuEl = isVisible ? <Menu isOpen={isOpen} setOpen={setOpen} setVisible={setVisible} img={data.placeholderImage.childImageSharp.fluid} data={data} location={location} /> : null;
    const contactsEl = isVisibleC ? <Contacts isOpenC={isOpenC} setOpenC={setOpenC} setVisibleC={setVisibleC} img={data.placeholderImage.childImageSharp.fluid} data={data} /> : null;
    
    return (
        <>
            <header className={classNames(s.header, s.headerMob)}>
                <Container>
                    <Row justify="justify-content-between" align="align-items-center">
                        <Link to={`${langBase}/`} className={s.logo}><Img fluid={data.placeholderImage.childImageSharp.fluid}/></Link>
                        <button type="button" className={classNames(s.phone, "ringing_phone")} onClick={() => {
                            setOpenC(true);
                            setVisibleC(true);
                        }}>
                            <span className={s.pulse}></span>
                            <Phone className="phone"/>
                        </button>
                        <button type="button" className={s.burger} onClick={() => {
                            setOpen(true);
                            setVisible(true);
                        }}>меню<span className={s.icon}><Burger/></span></button>
                    </Row>
                </Container>
            </header>
            { menuEl }
            { contactsEl }
        </>
    )
    
}

const Menu = ({isOpen, setOpen, setVisible, img, data, location}) => {
    const {local: { langBase, YAMLGlobal}} = useContext(LayoutContext);
    
    const [currentLocationRu, setCurrentLocationRu] = useState("/");
    const [currentLocationUa, setCurrentLocationUa] = useState("/ua");

    useEffect(() => {
        if (location.pathname.match("/ua") && location.pathname.match("/ua").index === 0) {
        if (location?.pathname?.length === 3) {
            setCurrentLocationRu(location.pathname.replace("/ua","/"));
        } else {
            setCurrentLocationRu(location.pathname.replace("/ua",""));
        }
        setCurrentLocationUa(location.pathname);
        } else {
        if (location.pathname.length === 1) {
            setCurrentLocationUa(location.pathname.replace("/","/ua"));
        } else {
            setCurrentLocationUa(location.pathname.replace("/","/ua/"));
        }
        setCurrentLocationRu(location.pathname);
        }
    }, [])
    
    const menu = {
        open: {
            opacity: 1,
        },
        close: {
            opacity: 0,
        }
    }
    
    return (
        <motion.div className={s.menu}
            variants={menu}
            transition={{duration: 0.2}}
            initial={{opacity: 0 }}
            animate={isOpen ? "open" : "close"}
            onAnimationComplete={() => {
                !isOpen && setVisible(false)
            }}
        >
            <div className={s.menuHeader}>
                <Link to={`${langBase}/`} className={s.logo}><Img fluid={img}/></Link>
                <button type="button" className={s.close} onClick={() => setOpen(false)}><Close/></button>
            </div>
            <nav className={s.nav}>
                <Dropdown title={YAMLGlobal.menu.services.main} className={s.navItem}>
                    <Link to={`${langBase}/services-home`} className={s.dropdownItem}>{YAMLGlobal.menu.services.home}</Link>
                    <Link to={`${langBase}/services-reserve`} className={s.dropdownItem}>{YAMLGlobal.menu.services.reserve}</Link>
                    <Link to={`${langBase}/services-auto`} className={s.dropdownItem}>{YAMLGlobal.menu.services.auto}</Link>
                    <Link to={`${langBase}/services-business`} className={s.dropdownItem}>{YAMLGlobal.menu.services.business}</Link>
                </Dropdown>
                <Link to={`${langBase}/projects/category/${data.projects.edges[0].node.slug}`} className={s.navItem}>{YAMLGlobal.menu.projects}</Link>
                <Link to={`${langBase}/green-tariff`} className={s.navItem}>{YAMLGlobal.menu.greenTariff}</Link>
                <Link to={`${langBase}/about-company`} className={s.navItem}>{YAMLGlobal.menu.about}</Link>
                <Link to={`${langBase}/video/category/${data.video.edges[0].node.slug}`} className={s.navItem}>{YAMLGlobal.menu.video}</Link>
                <Link to={`${langBase}/blog`} className={s.navItem}>{YAMLGlobal.menu.blog}</Link>
                <div className={s.socials}>
                    { data.info.edges[0].node.instagram ? <a rel="noreferrer noopener" id='inst' className={s.social} href={`${data.info.edges[0].node.instagram}`} target="_blank"><Instagram/></a> : null }
                    { data.info.edges[0].node.facebook ? <a rel="noreferrer noopener" id='fb' className={s.social} href={`${data.info.edges[0].node.facebook}`} target="_blank"><Facebook/></a> : null }
                    { data.info.edges[0].node.youtube ? <a rel="noreferrer noopener" id='youtube' className={s.social} href={`${data.info.edges[0].node.youtube}`} target="_blank"><Youtube/></a> : null }
                </div>
                <div className={s.language}>
                    <p style={{textDecoration: langBase === "" ? "underline" : "none"}}><Link to={currentLocationRu}>RU</Link></p>
                    <p style={{textDecoration: langBase === "/ua" ? "underline" : "none"}}><Link to={currentLocationUa}>UA</Link></p>
                </div>
                <div className={s.numbers}>
                    <a className={classNames(s.number, 'binct-phone-number-5')} href={`tel:${data.info.edges[0].node.phone_number_1}`}>{data.info.edges[0].node.phone_number_1}</a>
                    { data.info.edges[0].node.phone_number_2 ? <a className={classNames(s.number, 'binct-phone-number-6')} href={`tel:${data.info.edges[0].node.phone_number_2}`}>{data.info.edges[0].node.phone_number_2}</a> : null }
                    { data.info.edges[0].node.phone_number_3 ? <a className={classNames(s.number, 'binct-phone-number-7')} href={`tel:${data.info.edges[0].node.phone_number_3}`}>{data.info.edges[0].node.phone_number_3}</a> : null }
                </div>
            </nav>
        </motion.div>
    )
}

const Dropdown = ({i, title, children, className}) => {
    const [expanded, setExpanded] = useState(0);
    
    return <Accordion i={i} title={title} expanded={expanded} setExpanded={setExpanded} text={children} theme="dropdown" className={className} />;
}

const Contacts = ({isOpenC, setOpenC, setVisibleC, img, data}) => {
    const {local: { langBase}} = useContext(LayoutContext);
    
    const menu = {
        open: {
            opacity: 1,
        },
        close: {
            opacity: 0,
        }
    }
    
    return (
        <motion.div className={s.menu}
            variants={menu}
            transition={{duration: 0.2}}
            initial={{opacity: 0 }}
            animate={isOpenC ? "open" : "close"}
            onAnimationComplete={() => {
                !isOpenC && setVisibleC(false)
            }}
        >
            <div className={s.menuHeader}>
                <Link to={`${langBase}/`} className={s.logo}><Img fluid={img}/></Link>
                <button type="button" className={s.close} onClick={() => setOpenC(false)}><Close/></button>
            </div>
            <nav className={s.nav}>
                <div className={s.numbersC}>
                    <a className={classNames(s.number, 'binct-phone-number-8')} href={`tel:${data.info.edges[0].node.phone_number_1}`}>{data.info.edges[0].node.phone_number_1}</a>
                    { data.info.edges[0].node.phone_number_2 ? <a className={classNames(s.number, 'binct-phone-number-9')} href={`tel:${data.info.edges[0].node.phone_number_2}`}>{data.info.edges[0].node.phone_number_2}</a> : null }
                    { data.info.edges[0].node.phone_number_3 ? <a className={classNames(s.number, 'binct-phone-number-10')} href={`tel:${data.info.edges[0].node.phone_number_3}`}>{data.info.edges[0].node.phone_number_3}</a> : null }
                </div>
                <div className={s.address}>
                    <p>{data.info.edges[0].node.form_address || data.info.edges[0].node.form_address_ua}</p>
                </div>
                <div className={s.email}>
                    <a href={`${data.info.edges[0].node.form_email}`}>{data.info.edges[0].node.form_email}</a>
                </div>
                <div className={s.socials}>
                    { data.info.edges[0].node.instagram ? <a rel="noreferrer noopener" className={s.social} href={`${data.info.edges[0].node.instagram}`} target="_blank"><Instagram/></a> : null }
                    { data.info.edges[0].node.facebook ? <a rel="noreferrer noopener" className={s.social} href={`${data.info.edges[0].node.facebook}`} target="_blank"><Facebook/></a> : null }
                    { data.info.edges[0].node.youtube ? <a rel="noreferrer noopener" className={s.social} href={`${data.info.edges[0].node.youtube}`} target="_blank"><Youtube/></a> : null }
                </div>
            </nav>
        </motion.div>
    )
}

export default HeaderMob;