import React, { useContext, useEffect, useState } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { Link } from "gatsby"
import { Row, Container, Button } from '../../../ui-kit'
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

import s from './Header.module.sass'

import Facebook from '../../../icons/header/facebook.svg'
import Instagram from '../../../icons/header/instagram.svg'
import Youtube from '../../../icons/header/youtube.svg'
// context
import {LayoutContext} from '../../Layouts/layout'

const Header = ({location}) => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "logo-header.png" }) {
        childImageSharp {
          fluid(maxWidth: 288) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      projects: allDirectusProjectsCategory(limit: 1) {
        edges {
          node {
            slug
          }
        }
      },
      video: allDirectusVideosCategory(limit: 1) {
        edges {
          node {
            slug
          }
        }
      },
      info: allDirectusGenericInformation {
        edges {
          node {
            instagram
            facebook
            youtube
            phone_number_1
            phone_number_2
            phone_number_3
          }
        }
      }
    }
  `)
  
  const {onScrollToForm, local: { langBase, YAMLGlobal}} = useContext(LayoutContext);
  const [currentLocationRu, setCurrentLocationRu] = useState("/");
  const [currentLocationUa, setCurrentLocationUa] = useState("/ua");

  useEffect(() => {
    if (location.pathname.match("/ua") && location.pathname.match("/ua").index === 0) {
      if (location?.pathname?.length === 3) {
        setCurrentLocationRu(location.pathname.replace("/ua","/"));
      } else {
        setCurrentLocationRu(location.pathname.replace("/ua",""));
      }
      setCurrentLocationUa(location.pathname);
    } else {
      if (location.pathname.length === 1) {
        setCurrentLocationUa(location.pathname.replace("/","/ua"));
      } else {
        setCurrentLocationUa(location.pathname.replace("/","/ua/"));
      }
      setCurrentLocationRu(location.pathname);
    }
  }, [])

  return (
    <header className={classNames(s.header, s.headerDesk)}>
      <Container>
        <Row align="align-items-center" justify="justify-content-between">
          <Link to={`${langBase}/`} className={s.logo}><Img fluid={data.placeholderImage.childImageSharp.fluid} /></Link>
          <div className={s.nav}>
            <span className={s.dropdownWrapper}>
              <span className={s.navItem}>{YAMLGlobal.menu.services.main}</span>
              <div className={s.dropdown}>
                <Link to={`${langBase}/services-home`} className={s.dropdownItem}>{YAMLGlobal.menu.services.home}</Link>
                <Link to={`${langBase}/services-reserve`} className={s.dropdownItem}>{YAMLGlobal.menu.services.reserve}</Link>
                <Link to={`${langBase}/services-auto`} className={s.dropdownItem}>{YAMLGlobal.menu.services.auto}</Link>
                <Link to={`${langBase}/services-business`} className={s.dropdownItem}>{YAMLGlobal.menu.services.business}</Link>
              </div>
            </span>
            <Link to={`${langBase}/projects/category/${data.projects.edges[0].node.slug}`} className={s.navItem}>{YAMLGlobal.menu.projects}</Link>
            <Link to={`${langBase}/green-tariff`} className={s.navItem}>{YAMLGlobal.menu.greenTariff}</Link>
            <Link to={`${langBase}/about-company`} className={s.navItem}>{YAMLGlobal.menu.about}</Link>
            <Link to={`${langBase}/video/category/${data.video.edges[0].node.slug}`} className={s.navItem}>{YAMLGlobal.menu.video}</Link>
            <Link to={`${langBase}/blog`} className={s.navItem}>{YAMLGlobal.menu.blog}</Link>
          </div>
          <div className={s.order}>
            <Button className={classNames("white", s.btn)} onClick={() => {onScrollToForm()}}>{YAMLGlobal.button.order}</Button>
          </div>
          <div className={s.language}>
            <p style={{textDecoration: langBase === "" ? "underline" : "none"}}><Link to={currentLocationRu}>RU</Link></p>
            <p style={{textDecoration: langBase === "/ua" ? "underline" : "none"}}><Link to={currentLocationUa}>UA</Link></p>
          </div>
          <div className={s.numbers}>
            <a className={classNames(s.number, 'binct-phone-number-2')} href={`tel:${data.info.edges[0].node.phone_number_1}`}>
              {data.info.edges[0].node.phone_number_1}
            </a>
            { data.info.edges[0].node.phone_number_2 ? <a className={classNames(s.number, 'binct-phone-number-3')} href={`tel:${data.info.edges[0].node.phone_number_2}`}>
              {data.info.edges[0].node.phone_number_2}
            </a> : null }
            { data.info.edges[0].node.phone_number_3 ? <a className={classNames(s.number, 'binct-phone-number-4')} href={`tel:${data.info.edges[0].node.phone_number_3}`}>
              {data.info.edges[0].node.phone_number_3}
            </a> : null }
          </div>
          <div className={s.socials}>
            { data.info.edges[0].node.instagram ? <a rel="noreferrer noopener" id='inst' className={s.social} href={`${data.info.edges[0].node.instagram}`} target="_blank"><Instagram/></a> : null }
            { data.info.edges[0].node.facebook ? <a rel="noreferrer noopener" id='fb' className={s.social} href={`${data.info.edges[0].node.facebook}`} target="_blank"><Facebook/></a> : null }
            { data.info.edges[0].node.youtube ? <a rel="noreferrer noopener" id='youtube' className={s.social} href={`${data.info.edges[0].node.youtube}`} target="_blank"><Youtube/></a> : null }
          </div>
        </Row>
      </Container>

    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
