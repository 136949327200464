import React, { useContext } from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import classNames from "classnames"

// ui-kit
import { Row, Container, Title, Form } from "../../../ui-kit"
// style
import s from "./FormOrder.module.sass"
// svg
import GrayCircles from "../../../icons/form-order/pattern_circles.svg"
import GreenCircles from "../../../icons/form-order/pattern_circles_green.svg"
import BalloonTriangle from "../../../icons/form-order/balloon-triangle.svg"
import Location from "../../../icons/form-order/location.svg"
import Phone from "../../../icons/form-order/phone.svg"
import Envelope from "../../../icons/form-order/envelope.svg"
import GrayWave from '../../../icons/waves/gray-wave.svg'
import WhiteWave from '../../../icons/form-order/white-wave.svg'
// context
import { LayoutContext } from "../../Layouts/layout"

const FormOrder = ({ withMap = false, background = [], wave = "white" }) => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "pattern_green.png" }) {
        childImageSharp {
          fluid(maxWidth: 217) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      placeholderImageMap: file(relativePath: { eq: "map.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      form: directusGenericInformation {
        form_image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        },
        form_number
        form_address
        form_address_ua
        form_email
      }
    }
  `)

  const LeftContent = () =>
    withMap ? <FormMap data={data} /> : <FormImage data={data} />

  const { formRef, local: { YAMLGlobal } } = useContext(LayoutContext);

  const waveC = wave === "gray" ? <GrayWave className={s.wave} /> : <WhiteWave className={s.wave} />
  
  return (
    <section
      className={classNames(s.formOrder, {
        [s.bgWhite]: background.includes("white"),
      })}
      ref={formRef}
    >
      { waveC }
      <Container size="fluid" padding="no-padding">
        <Row direction="row-reverse">
          <div className={s.form}>
            <div className={s.wrapper}>
              <Title className={s.title}>{YAMLGlobal.form.orderText}</Title>
              <Form text={YAMLGlobal.form} />
            </div>
            <div className={s.greenRect}>
              <Img fluid={data.placeholderImage.childImageSharp.fluid} />
            </div>
            <div className={classNames('no-pointer', s.grayCircles)}>
              <GrayCircles />
            </div>
            <div className={classNames('no-pointer', s.greenCircles)}>
              <GreenCircles />
            </div>
          </div>
          <LeftContent />
        </Row>
      </Container>
    </section>
  )
}

const FormMap = ({data}) => {
    const { local: { langBase } } = useContext(LayoutContext);

    return (
      <div className={s.map}>
        <div className={s.mapImg}>
            <Img fluid={data.placeholderImageMap.childImageSharp.fluid} className={s.mapImg}/>
        </div>
        <span className={s.placemark}></span>
        <div className={s.balloon}>
            <p className={s.address}>
            <i className={s.icon}>
                <Location />
            </i>
                <a href="https://www.google.ru/maps/place/UTEM+SOLAR/@50.4122024,30.6508193,17z/data=!3m1!4b1!4m5!3m4!1s0x40d4c4fdbd269113:0x9aeb457c539b3654!8m2!3d50.412199!4d30.653008" target="_blank" rel="nooreferrer">{ langBase ? data.form.form_address_ua : data.form.form_address}</a>
            </p>
            <p className={s.phone}>
            <i className={s.icon}>
                <Phone />
            </i>
            <a className="binct-phone-number-1" href={`tel:${data.form.form_number}`}>{data.form.form_number}</a>
            </p>
            <p className={s.email}>
            <i className={s.icon}>
                <Envelope />
            </i>
            <a href={`mailto:${data.form.form_email}`}>{data.form.form_email}</a>
            </p>
            <BalloonTriangle className={s.edge} />
        </div>
      </div>
    )
}

const FormImage = ({ data }) => {
  return (
    <div className={classNames(s.map, s.hideTablet)}>
      <Img fluid={data.form.form_image.localFile ? data.form.form_image.localFile?.childImageSharp.fluid : ""} className="height100 width100" />
    </div>
  )
}

export default FormOrder